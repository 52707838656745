import axios from './axios'

export const domain = 'dev-z4u5i1cf.us.auth0.com'
const clientId = 'TKarirpVBoAXd4zE7KkQanazm5nitRv5'

export default () => {
	return {
		domain: domain,
		clientId: clientId,
		redirect_url: window.location.origin
	}
}
