import { Button, Table } from "antd";
import {organisationFolderContentsData} from "./constants";
import Filter from "../../../shared/Filter";
import Search from "../../../shared/Search";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import {organisationPage} from "../constants";
import {ReactSVG} from "react-svg";
import {useContextState} from "../../../context/ContextProvider";
import {useEffect, useState} from "react";
import {downloadFileFromResponse, downloadModuleBackup, downloadTotalBackup, getFolderModules, getModuleAttachments, moduleFileDownload} from "../../../utils/organization";
import {addToNestedHeader} from "../../../features/nested_header/nested_header";
import {useDispatch} from "react-redux";

const FolderContents = ({orgId, date, onModuleEnter}) => {
    const {user, isAuthenticated, isLoading, getIdTokenClaims} = useAuth0();
    const navigate = useNavigate();
    const [contents, setContents] = useState([]);
    const [loadingButton, setLoadingButton] = useState(null); // Tracks the currently loading button
    const [wholeBackupLoadingButton, setWholeBackupLoadingButton] = useState(null); // Tracks the currently loading button
	const [tableLoading, setTableLoading] = useState(true)

    if (!isAuthenticated) {
        navigate("/login");
    }

    const {setBreadCrumb} = useContextState();
    const {setNestedHeader} = useContextState();
	const dispatch = useDispatch()

    useEffect(() => {
        if (!isLoading && isAuthenticated) {
            (async () => {
                try {
                    const token = await getIdTokenClaims();

                    getFolderModules(token.__raw, orgId, date).then(res => {
                        setContents(res.data.files);
                    }).catch(err => {
                        console.log("err", err);
                    }).finally(() => {
						setTableLoading(false)
					})
                } catch (e) {}
            })();
        }
    }, [isLoading, isAuthenticated]);

    const downloadFile = async (link, buttonId) => {
        setLoadingButton(buttonId); // Set the button ID as loading
        try {
            const token = await getIdTokenClaims();

            moduleFileDownload(token.__raw, link).then(res => {
                downloadFileFromResponse(res);
            }).catch(err => {
                console.error(err);
            }).finally(() => {
                setLoadingButton(null); // Reset the loading state
            });
        } catch (e) {
            setLoadingButton(null);
        }
    };

	const handleAttachmentsClick = async (id, moduleName, date) => {
		try {
			const token = await getIdTokenClaims()

			getModuleAttachments(token.__raw, id, date, moduleName).then(res => {
				console.log(res.data)
			}).catch(err => {
				console.error(err)
			})
		} catch(e) {
			console.log(e)
		}
	} 

	const downloadBackup = async (file, buttonId) => {
        setLoadingButton(buttonId); // Set the button ID as loading
        try {
            const token = await getIdTokenClaims();

            downloadModuleBackup(token.__raw, file.xero_id, file.rec_date, file.file).then(res => {
                downloadFileFromResponse(res);
            }).catch(err => {
                console.error(err);
            }).finally(() => {
                setLoadingButton(null); // Reset the loading state
            });
        } catch (e) {
            setLoadingButton(null);
        }
	}

	const downloadWholeBackup = async () => {
        setWholeBackupLoadingButton(true); // Set the button ID as loading
        try {
            const token = await getIdTokenClaims();

            downloadTotalBackup(token.__raw, orgId, date).then(res => {
                downloadFileFromResponse(res);
            }).catch(err => {
                console.error(err);
            }).finally(() => {
                setWholeBackupLoadingButton(false); // Reset the loading state
            });
        } catch (e) {
            setWholeBackupLoadingButton(false);
        }
	}

    const columns = [
        {
            title: "Module",
            dataIndex: "module",
            key: "module",
            width: "60%",
            render: (key, {id, file, size}) => {
                return (
                    <div className="cursor-pointer flex justify-between items-center">
                        <div
                            onClick={
                                ["balance_sheet", "trial_balance", "profit_and_loss"].includes(file)
                                    ? null // Do nothing if the file is restricted
                                    : () => {
                                        onModuleEnter(id, file);
                                        const nextPage = {
                                            header: file,
                                            page: organisationPage.ORGANISATION_FOLDER_CONTENTS_DETAILS,
                                        };
                                        const currPage = {
                                            orgId,
                                            date,
                                            onModuleEnter,
                                        };
                                        dispatch(addToNestedHeader(nextPage));
                                        setNestedHeader(nextPage);
                                        setBreadCrumb(prevState => ([...prevState, { label: file, url: window.location.href }]));
                                    }
                            }
                            className={`flex items-center gap-3 py-1.5 ${
                                ["balance_sheet", "trial_balance", "profit_and_loss"].includes(file) 
                                    ? "cursor-default" // Non-clickable style for restricted files
                                    : "cursor-pointer" // Pointer style for clickable items
                            }`}
                        >
                            <ReactSVG src={"/assets/icons/folder.svg"} />
                            {file} {
								size ?
									(<span>({size} MB)</span>):
									null
							}
                        </div>
                    </div>
                );
            },
        },
        {
            title: "Records",
            dataIndex: "records",
            key: "records",
            render: (_, {count}) => {
                return <div className="flex gap-2">{count}</div>;
            },
        },
        {
            title: "Actions",
            dataIndex: "actions",
            key: "actions",
            render: (_, val) => (
                <div className="flex gap-2">
                    {(() => {
                        const buttons = [];
                        if (val.count == 0)
                            return buttons;

                        if (val.csv_id) {
                            buttons.push(
                                <Button
                                    key={`csv-${val.csv_id}`}
                                    className="px-3 font-medium py-1 mr-2 rounded-lg border border-gray-300"
                                    onClick={() => downloadFile(val.csv_link, `csv-${val.csv_id}`)}
                                    loading={loadingButton === `csv-${val.csv_id}`}
                                >
                                    CSV
                                </Button>
                            );
                        }
                        if (val.excel_id) {
                            buttons.push(
                                <Button
                                    key={`excel-${val.excel_id}`}
                                    className="px-3 font-medium py-1 mr-2 rounded-lg border border-gray-300"
                                    onClick={() => downloadFile(val.xls_link, `excel-${val.excel_id}`)}
                                    loading={loadingButton === `excel-${val.excel_id}`}
                                >
                                    Excel
                                </Button>
                            );
                        }
                        if (val.json_id) {
                            buttons.push(
                                <Button
                                    key={`json-${val.json_id}`}
                                    className="px-3 font-medium py-1 mr-2 rounded-lg border border-gray-300"
                                    onClick={() => downloadFile(val.json_link, `json-${val.json_id}`)}
                                    loading={loadingButton === `json-${val.json_id}`}
                                >
                                    JSON
                                </Button>
                            );
                        }
                        if (val.attach_count && val.attach_count > 0) {
                            buttons.push(
                                <div
                                    key={`attachments-${val.attach_count}`}
                                    className="px-3 font-medium py-1 mr-2 rounded-lg border border-gray-300"
								onClick={() => handleAttachmentsClick(val.xero_id, val.file, val.rec_date)}
                                >
                                    Attachments ({val.attach_count})
                                </div>
                            );
                        }

						buttons.push(
                                <Button
                                    key={`download-backup-${val.id}`}
                                    className="px-3 font-medium py-1 mr-2 rounded-lg border border-gray-300"
                                    onClick={() => downloadBackup(val, `download-backup-${val.id}`)}
                                    loading={loadingButton === `download-backup-${val.id}`}
                                >
                                    Download {val.file.replace(/_/g, ' ')}
                                </Button>
						)

                        return buttons;
                    })()}
                </div>
            ),
        },
    ];

    return (
        <div className="lg:px-8 px-3 pb-10 mb-4">
			<div className={'flex items-center justify-between'}>
				<Button type="primary" onClick={downloadWholeBackup} loading={wholeBackupLoadingButton}>Download</Button>
			</div>

            <div className="mt-6  lg:block hidden">
                <Table dataSource={contents} columns={columns}
					loading={tableLoading}/>
            </div>
        </div>
    );
};

export default FolderContents;
