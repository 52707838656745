import { Auth0Provider } from "@auth0/auth0-react";
import { closeSnackbar, SnackbarProvider } from "notistack";
import { Provider } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.scss";
import { useContextState } from "./context/ContextProvider";
import Archive from "./pages/Archive";
import AuditLog from "./pages/AuditLog";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Logout from "./pages/Logout";
import NotFound from "./pages/NotFound";
import Organization from "./pages/Organization";
import PaymentMethods from "./pages/PaymentMethods";
import Settings from "./pages/Settings";
import Users from "./pages/Users";
import Feedback from "./shared/Feedback";
import SideNav from "./shared/SideNav";
import TopNav from "./shared/TopNav";
import SilentAuth from "./SilentAuth";
import store from "./store.js";
import getAuth0Config from "./utils/auth0.js";
import RouteChangeListener from "./utils/route_change_listener";

const App = () => {
  const { collapseSideBar } = useContextState();
  const auth0Config = getAuth0Config();

  return (
    <SnackbarProvider
      maxSnack={2}
      autoHideDuration={3000}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      preventDuplicate
      hideIconVariant
      action={(snackbarId) => (
        <button onClick={() => closeSnackbar(snackbarId)}>Close</button>
      )}
    >
      <Auth0Provider
        domain={auth0Config.domain}
        clientId={auth0Config.clientId}
        authorizationParams={{
          redirect_uri: auth0Config.redirect_url,
        }}
        cacheLocation="localstorage"
        useRefreshTokens={true}
      >
        <SilentAuth />
        <Provider store={store}>
          <BrowserRouter>
            <div>
              <SideNav />
              <div className={`${collapseSideBar ? "sm:ml-20" : "sm:ml-64"}`}>
                <TopNav />
                <div className={"pt-6"}>
                  <RouteChangeListener />
                  <Routes>
                    <Route path={"/"} element={<Home />} />
                    <Route path={"/login"} element={<Login />} />
                    <Route path={"/users"} element={<Users />} />
                    <Route path={"/archive"} element={<Archive />} />
                    <Route path={"/settings"} element={<Settings />} />
                    <Route path={"/audit-log"} element={<AuditLog />} />
                    <Route path={"/organizations"} element={<Organization />} />
                    <Route
                      path={"/payment-methods"}
                      element={<PaymentMethods />}
                    />
                    <Route path={"/logout"} element={<Logout />} />
                    <Route path="*" element={<NotFound />} />
                  </Routes>
                </div>
              </div>
            </div>
          </BrowserRouter>
        </Provider>
        <Feedback />
      </Auth0Provider>
    </SnackbarProvider>
  );
};

export default App;
