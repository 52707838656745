// ThemeContext.js
import React, { createContext, useContext, useEffect, useState } from "react";

const AppContext = createContext();

const breadCrumbAllowedUrl = ["/organizations", ""];

export const ContextProvider = ({ children }) => {
  const [breadCrumb, setBreadCrumb] = useState([]);
  const [nestedHeader, setNestedHeader] = useState(null);
  const [collapseSideBar, setCollapseSideBar] = useState(false);
  const [openLoginPopup, setOpenLoginPopup] = useState(false);

  useEffect(() => {});

  return (
    <AppContext.Provider
      value={{
        breadCrumb,
        nestedHeader,
        openLoginPopup,
        collapseSideBar,
        setBreadCrumb,
        setNestedHeader,
        setOpenLoginPopup,
        setCollapseSideBar,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useContextState = () => useContext(AppContext);
