import { useAuth0 } from "@auth0/auth0-react";
import { Button, Typography } from "antd";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { updatePaymentMethod } from "../../utils/payment_methods";

const { Text } = Typography;

const InputField = ({
  label,
  name,
  type = "text",
  placeholder,
  value,
  onChange,
}) => (
  <div className="w-full mb-2">
    <label className="font-medium text-sm mb-2 block" htmlFor={name}>
      {label}
    </label>
    <input
      id={name}
      name={name}
      type={type}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      className="w-full bg-gray-100 rounded-lg border-gray-200 border p-2"
    />
  </div>
);

const UpdateCardModal = ({ paymentMethod, onUpdate }) => {
  const [formData, setFormData] = useState({
    name: paymentMethod.billing_details.name,
    email: paymentMethod.billing_details.email,
    phone: paymentMethod.billing_details.phone,
    country: paymentMethod.billing_details.address.country,
    state: paymentMethod.billing_details.address.state,
    city: paymentMethod.billing_details.address.city,
    line1: paymentMethod.billing_details.address.line1,
    postal_code: paymentMethod.billing_details.address.postal_code,
    exp_month: paymentMethod.card.exp_month,
    exp_year: paymentMethod.card.exp_year,
  });

  const [formSubmitting, setFormSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const { enqueueSnackbar } = useSnackbar();

  const { getIdTokenClaims } = useAuth0();

  useEffect(() => {
    setFormSubmitting(false);
    setErrorMessage("");
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = await getIdTokenClaims();

      setFormSubmitting(true);
      setErrorMessage("");

      updatePaymentMethod(token.__raw, paymentMethod.id, formData)
        .then((res) => {
          if (res.data.success) {
            setErrorMessage("");
            enqueueSnackbar("Updated Successfully", {
              variant: "success",
            });
            onUpdate();
          } else {
            setErrorMessage(res.data.message);
          }
        })
        .catch((err) => {
          console.error(err);
          setErrorMessage(
            "An error occurred while updating the payment method."
          );
        })
        .finally(() => {
          setFormSubmitting(false);
        });
    } catch (err) {
      console.error(err);
      setErrorMessage("Failed to retrieve authentication token.");
    }
  };

  const fields = [
    { label: "Name", name: "name" },
    { label: "Email", name: "email" },
    { label: "Phone", name: "phone" },
    { label: "Two-letter country code", name: "country" },
    { label: "State", name: "state" },
    { label: "City", name: "city" },
    { label: "Address line1", name: "line1" },
    { label: "Postal Code", name: "postal_code", type: "number" },
  ];

  return (
    <div className="py-6 text-sm border-b border-gray-300 w-full">
      <form onSubmit={handleFormSubmit}>
        {fields.map((field) => (
          <InputField
            key={field.name}
            {...field}
            value={formData[field.name]}
            onChange={handleInputChange}
          />
        ))}

        <p className="font-medium text-sm mb-2">Card Expiry</p>
        <div className="flex items-center justify-between gap-3">
          <InputField
            name="exp_month"
            placeholder="Month"
            value={formData.exp_month}
            onChange={handleInputChange}
          />
          <InputField
            name="exp_year"
            placeholder="Year"
            value={formData.exp_year}
            onChange={handleInputChange}
          />
        </div>

        {errorMessage && (
          <div className="flex py-5">
            <Text type="danger">{errorMessage}</Text>
          </div>
        )}

        <div className="flex justify-end items-center mt-3">
          <Button type="primary" htmlType="submit" loading={formSubmitting}>
            Update
          </Button>
        </div>
      </form>
    </div>
  );
};

export default UpdateCardModal;
