import { useAuth0 } from "@auth0/auth0-react";
import { Modal } from "antd";
import { deleteOrg } from "../../utils/organization";

const DeleteOrgModal = ({
  orgId,
  organizationName,
  modalStatus,
  setModalStatus,
  onModalClose,
  onSuccess,
}) => {
  let { getIdTokenClaims } = useAuth0();

  const handleDeleteOrg = () => {
    (async () => {
      try {
        let token = await getIdTokenClaims();

        deleteOrg(token.__raw, orgId)
          .then((res) => {
            onSuccess();
          })
          .catch((err) => {
            console.error("err", err);
          });
      } catch (e) {}
    })();
  };

  return (
    <Modal
      title={[<div className={"text-2xl"}>Delete Organization</div>]}
      centered
      open={modalStatus}
      footer={[
        <div className={"flex items-center justify-end gap-3"}>
          <div
            key="cancel"
            className={
              "px-2 text-sm py-1.5 border border-gray-200 rounded-lg cursor-pointer"
            }
            onClick={() => {
              setModalStatus(false);
              onModalClose();
            }}
          >
            Cancel
          </div>

          <div
            key="submit"
            className={
              "px-2 text-sm py-1.5 bg-blue-500 text-white rounded-lg cursor-pointer"
            }
            onClick={handleDeleteOrg}
          >
            Confirm
          </div>
        </div>,
      ]}
      onOk={() => {
        setModalStatus(false);
        onModalClose();
      }}
      onCancel={() => {
        setModalStatus(false);
        onModalClose();
      }}
    >
      <div className={"py-8 text-gray-500 text-sm border-b border-gray-300"}>
        <div>
          Are you sure you want to delete
          <b className={"ml-2"}>
            {Array.isArray(orgId)
              ? `${orgId?.length} organizations`
              : organizationName}
          </b>
          ?
        </div>
        <div className={"mt-6"}>
          This action will delete all the data, and it cannot be undone.
        </div>
      </div>
    </Modal>
  );
};

export default DeleteOrgModal;
