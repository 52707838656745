import { useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';

const SilentAuth = () => {
  const { getAccessTokenSilently, isAuthenticated, loginWithRedirect } = useAuth0();

  useEffect(() => {
    const checkToken = async () => {
      if (isAuthenticated) {
        try {
          await getAccessTokenSilently();
        } catch (error) {
          if (error.error === 'login_required') {
            loginWithRedirect(); // Redirect to login if session is not valid
          }
        }
      }
    };

    checkToken();

    // Set interval to renew token periodically
    const interval = setInterval(() => {
      checkToken();
    }, 15 * 60 * 1000); // 15 minutes

    return () => clearInterval(interval);
  }, [getAccessTokenSilently, isAuthenticated, loginWithRedirect]);

  return null; // No UI needed for SilentAuth
};

export default SilentAuth;
