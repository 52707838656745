import {Button, Modal} from "antd";
import {useEffect, useState} from "react";

const ConfirmModal = ({
	title, text, modalOpen, onCancel, onOkay
}) => {
    const [lmodalOpen, setModalOpen] = useState(false);

	useEffect(() => {
		setModalOpen(modalOpen)
	}, [modalOpen])

    return (
        <Modal
            title={[<div className={'text-2xl'}>{title}</div>]}
            centered
            open={lmodalOpen}
            footer={[
                <div className={'flex items-center justify-end gap-3'}>
                    <Button htmlType="button" type="secondary" onClick={() => {setModalOpen(false); onCancel();}}>
						Cancel	
                    </Button>

                    <Button htmlType="button" type="primary" onClick={() => {setModalOpen(false); onOkay();}}>
						Okay
                    </Button>
                </div>
            ]}
            onOk={() => {setModalOpen(false); onOkay()}}
            onCancel={() => {setModalOpen(false); onCancel()}}
        >
			<div className={'py-8 text-sm border-b border-gray-300 w-full'}>
				<div className={'w-full mb-6'}>
					{text}
				</div>
			</div>
		</Modal>
    )
}

export default ConfirmModal;


