import {invoiceData} from "./constant";
import React, {useRef, useState}  from 'react'
import {useReactToPrint} from "react-to-print";
import PrintableComponent from "./printable";
import './invoiceModal.scss'
import {Button} from "antd";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";

const InvoiceModal = ({invoice}) => {
	const printRef = useRef()
	const [printingStatus, setPrintingStatus] = useState(false)

	const downloadAsPDF = () => {
		setPrintingStatus(true)
		setTimeout(async () => {
			const printable = document.getElementById("printable_component");
			if (!printable) return;

			// Capture the popup content as an image
			const canvas = await html2canvas(printable);
			const imgData = canvas.toDataURL("image/png");

			// Generate the PDF
			const pdf = new jsPDF("p", "mm", "a4");
			const pdfWidth = pdf.internal.pageSize.getWidth();
			const pdfHeight = (canvas.height * pdfWidth) / canvas.width;

			pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
			pdf.save("popup-content.pdf");
			setPrintingStatus(false)
		}, 300)
	}

    const columns = [
        {
            title: 'Date',
            dataIndex: 'payment_time',
            key: 'date',
        },
        {
            title: 'Description',
            dataIndex: 'service_name',
            key: 'description',
        },
        {
            title: 'Service Period',
            key: 'servicePeriod',
			render: (_, {subs_current_period_start, subs_current_period_end}) => {
				return (
					<div>
						{subs_current_period_start.slice(0, 10)} - {subs_current_period_end.slice(0, 10)}
					</div>
				)
			}
        },
        {
            title: 'Amount',
            key: 'amount',
			render: (_, {amount_paid, tax_amount}) => {
				return (
					<div>
						$ {(amount_paid - tax_amount) / 100}
					</div>
				)
			},
			width: "15%"
        },
        {
            title: 'Tax',
            key: 'tax',
			render: (_, {tax_amount}) => {
				return (
					<div>
						$ {tax_amount / 100}
					</div>
				)
			},
			width: "15%"
        },
        {
            title: 'Total',
            key: 'total',
			render: (_, {amount_paid}) => {
				return (
					<div>
						$ {amount_paid / 100}
					</div>
				)
			},
			width: "15%"
        },
    ];
	
	const SimpleComponent = React.forwardRef((_, ref) => (
	  <div ref={ref}>
		<h1>Simple Component</h1>
	  </div>
	));

    return (
		<div className={'my-10'}>
			<PrintableComponent ref={printRef} invoice={invoice} columns={columns} isPrinting={printingStatus} />
			
			<Button onClick={() => {
				downloadAsPDF()
			}} id="invoice-print-btn">
				Print
			</Button>
		</div>
    )
}

export default InvoiceModal;
