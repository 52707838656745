// CommonStore.js
import { action, makeAutoObservable, observable } from "mobx";

class CommonStore {
  addCardModalOpen = false;

  constructor() {
    makeAutoObservable(this, {
      //observables
      addCardModalOpen: observable,

      //actions
      setAddCardModalOpen: action,
    });
  }

  setAddCardModalOpen(value) {
    this.addCardModalOpen = value;
  }
}

export const commonStore = new CommonStore();
