import {ReactSVG} from "react-svg";
import Separator from "../../../shared/Separator";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import {
	getCompany,
	getNotificationPreferences,
	getUser,
	resetPassword,
	saveProfile,
	setNotificationPreferences
} from '../../../utils/users'
import {useEffect, useState} from 'react'
import SuccessModal from "../../../modals/SuccessModal";
import {Button} from "antd";

const MyProfile = () => {
	const {isAuthenticated, isLoading, getIdTokenClaims} = useAuth0()
	const navigate = useNavigate()
	const [profileData, setProfileData] = useState({})
	const [companyData, setCompanyData] = useState({})
	const [notificationPreference, setNotificationPreference] = useState({});
	const [successModalOpen, setSuccessModalOpen] = useState(false)
	const [isSubmitting, setIsSubmitting] = useState(false)

	if(!isAuthenticated) {
		navigate("/login")
	}

	useEffect(() => {
		getNotificationPreferencesAPI();
		if(!isLoading && isAuthenticated) {
			fetchProfile()
			fetchCompany()
		}
	}, [isLoading, isAuthenticated])

	const fetchProfile = async () => {
		const token = await getIdTokenClaims();

		getUser(token.__raw).then(res => {
			setProfileData(res.data)
		}).catch(err => {
			console.error(err)
		})
	}

	const getNotificationPreferencesAPI = async () => {
		const token = await getIdTokenClaims();
		const {data: {data}} = await getNotificationPreferences(token.__raw);
		setNotificationPreference(data);
	}

	const notificationPreferencesAPI = async (id, status) => {
		const newNotificationPref = notificationPreference.map(item => {
			if (item.id === id) {
				item.notification_enabled = status;
			}
			return item;
		});
		setNotificationPreference(newNotificationPref);
		const token = await getIdTokenClaims();
		try {
			const {data} = await setNotificationPreferences(token.__raw, id, status);
			getNotificationPreferencesAPI();
		} catch (e) {
			console.log(e);
		}

	}

	const fetchCompany = async () => {
		const token = await getIdTokenClaims()

		getCompany(token.__raw).then(res => {
			setCompanyData(res.data.company ?? {})
		}).catch(err => {
			console.error(err)
		})
	}

	const handleSubmit = async (e) => {
		e.preventDefault()
		setIsSubmitting(true)

		try {
			const token = await getIdTokenClaims()

			saveProfile(token.__raw, profileData, companyData).then(res => {
				console.log(res.data)

				setSuccessModalOpen(true)
			}).catch(err => {
				console.error(err)
			}).finally(() => {
				setIsSubmitting(false)
			})
		} catch(e) {
			console.error(e)
			setIsSubmitting(false)
		}
	}

	const handleSetNewPassword = async () => {
		if(window.confirm("Are you sure you want to reset your password?")) {
			try {
				const token = await getIdTokenClaims()

				resetPassword(token.__raw).then(res => {
					if(res.data.error === 1) {
						alert("Something went wrong while resetting the password. Please contact support!")
					} else if(res.data.error === 0) {
						alert("Password reset link has been sent to your registered email.")
					}
				}).catch(err => {
					alert("Something went wrong while resetting the password. Please contact support!")
					console.error(err)
				})
			}catch(e) {

			}
		}
	}

    return <div className={'flex gap-8 mb-8'}>
        <div className={'w-[35rem] h-[16rem] border border-gray-300 rounded-2xl p-4 text-sm text-gray-500 flex flex-col justify-center items-center'}>
            <img src="/assets/image/default-avatar.png" alt="default-avatar" className={'w-32 h-32 mb-6 rounded-full'}/>
			{profileData.email}
        </div>
        <div className={'w-full border border-gray-300 rounded-2xl p-6 text-sm'}>
			<form onSubmit={handleSubmit}>
				<div>
					<div>
						<div className={'text-lg font-medium'}>Account Data</div>
						<div className={'sm:flex gap-8 mt-4'}>
							<div className={'sm:w-[60%] w-full sm:mb-0 mb-5'}>
								<div className={'text-sm text-gray-700 mb-3'}>
									<p>Full name</p>
									<input type="text" placeholder={'Enter full name'} className={'rounded-lg px-3 py-2 border border-gray-300 mt-1 w-full'} value={profileData.name}
										onChange={(e) => {
											setProfileData({...profileData, name: e.target.value})
										}}
									/>
								</div>
								<div className={'text-sm text-gray-700 mb-4'}>
									<p>Email</p>
									<input type="text" placeholder={'Enter your email'} className={'rounded-lg px-3 py-2 border border-gray-300 mt-1 w-full disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none'} value={profileData.email} disabled />
								</div>
								<div className={'text-sm text-gray-700 mb-4'}>
									<p>Company Name</p>
									<input type="text" placeholder={'Enter your company name'} className={'rounded-lg px-3 py-2 border border-gray-300 mt-1 w-full'} value={companyData.company_name}
										onChange={(e) => {
											setCompanyData({...companyData, company_name: e.target.value})
										}}
									/>
								</div>
								<div className={'text-sm text-gray-700 mb-4'}>
									<p>Company Registration Number</p>
									<input type="text" placeholder={'Enter company registration number'} className={'rounded-lg px-3 py-2 border border-gray-300 mt-1 w-full'} value={companyData.company_registration_number}
										onChange={(e) => {
											setCompanyData({...companyData, company_registration_number: e.target.value})
										}}
									/>
								</div>
								<div className={'text-sm text-gray-700 mb-4'}>
									<p>Company Phone</p>
									<input type="tel" placeholder={'Enter company phone'} className={'rounded-lg px-3 py-2 border border-gray-300 mt-1 w-full'} value={companyData.company_phone}
										onChange={(e) => {
											setCompanyData({...companyData, company_phone: e.target.value})
										}}
									/>
								</div>
								<div className={'text-sm text-gray-700'}>
									<p>Company Address</p>
									<textarea placeholder={'Enter company address'} className={'rounded-lg px-3 py-2 border border-gray-300 mt-1 w-full'} value={companyData.company_address}
										onChange={(e) => {
											setCompanyData({...companyData, company_address: e.target.value})
										}}
									/>
								</div>
							</div>
							<div className={'rounded-xl bg-slate-100 p-3 h-max'}>
								<b className={'block'}>Update your password through the button below</b> You will be redirected to a new page and must follow the instructions.
								<button className={'flex items-center gap-3 bg-white border border-blue-500 text-blue-600 font-medium text-sm px-3 py-2 mt-3 rounded-lg'} onClick={handleSetNewPassword} type="button">
									Set New Password
									<ReactSVG src={'/assets/icons/external-link.svg'} />
								</button>
							</div>
						</div>
						<Separator />
					</div>
					<div>
						<div className={'text-lg font-medium'}>Email notifications</div>
						<div className={'mt-4'}>
							{notificationPreference.length ? notificationPreference.map((item, index) => (
							<div key={item.id}
								className={`flex py-5 justify-between text-sm ${index === 0 ? 'border-t' : ''} border-b border-gray-200`}>
								<div>{item.name}</div>
								<label className="inline-flex items-center cursor-pointer">
									<input type="checkbox"
										   checked={item.notification_enabled}
										   onChange={(e) => {
											   notificationPreferencesAPI(item.id, !item.notification_enabled)
										   }}
										   className="sr-only peer"/>
									<div
										className="relative w-9 h-3 bg-gray-400 peer-focus:outline-none rounded-full peer peer-checked:after:translate-x-[90%] rtl:peer-checked:after:-translate-x-full peer-checked:after:border-blue-500 after:content-[''] after:absolute after:-top-[3.5px] after:-start-[1px] after:rounded-full after:h-5 after:w-5 after:transition-all after:bg-white after:shadow-sm after:border peer-checked:after:bg-blue-500 peer-checked:bg-blue-300"></div>
								</label>
							</div>)) : ''}
							{/* <div className={'mt-10 sm:flex items-center'}>
								<button className={'bg-slate-50 rounded-lg mr-2 px-3 py-2 text-rose-500 sm:mb-0 mb-3'}>
									Delete my account
								</button>
								<div>
									You cannot delete your account when your subscription is active
								</div>
							</div> */}
						</div>
						<Separator />
						<div className={'w-full flex items-center justify-end gap-3 text-sm'}>
							<Button htmlType="submit" type="primary" loading={isSubmitting}>Save Changes</Button>
						</div>
					</div>
				</div>
			</form>
        </div>

		<SuccessModal title="Profile update" text="Successfully updated your profile!" modalOpen={successModalOpen} onModalClose={() => {setSuccessModalOpen(false)}} />
    </div>
}

export default MyProfile;
