import {domain} from "./auth0"
import axios from "./axios"

export const checkAuthToken = () => {

}

export const resendUserVerificationEmail = (token, userId) => {
	return axios.post("/auth0/resend-verification-email", {}, {
		headers: {
			"API-TOKEN": token,
		}
	})
}

export const checkUserVerificationStatus = (token) => {
	return axios.post("/auth0/check-email-verification", {}, {
		headers: {
			"API-TOKEN": token,
		}
	})
}
