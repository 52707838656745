import {ReactSVG} from "react-svg";
import {Link, useLocation} from 'react-router-dom';
import {getPageHeader} from "../../utils/utils";
import {useContextState} from "../../context/ContextProvider";
import {useEffect, useState} from "react";
import {Dropdown, Button} from "antd";
import {notificationData} from "./constant";
import {getAllNotifications, getNotificationCount, getTodayNotifications, readAllNotification} from "../../utils/notifications";
import {useAuth0} from "@auth0/auth0-react";
import {checkAuthentication, checkVerification} from "../../utils/users";
import {checkUserVerificationStatus, resendUserVerificationEmail} from "../../utils/auth";
import {useDispatch, useSelector} from "react-redux";
import {goBack} from "../../features/nested_header/nested_header";

const TopNav = () => {
    const location = useLocation();
    const {breadCrumb, setBreadCrumb, nestedHeader, setNestedHeader, collapseSideBar, setCollapseSideBar} = useContextState();
	const {isAuthenticated, getIdTokenClaims, logout, user} = useAuth0()
	const [notificationCount, setNotificationCount] = useState(0)
	const [todayNotifications, setTodayNotifications] = useState([])
	const [allNotifications, setAllNotifications] = useState([])
	const [username, setUsername] = useState('')
	const [email, setEmail] = useState('')
	const [resendingEmailStatus, setResendingEmailStatus] = useState(false)
	const [resentVerificationEmailStatus, setResentVerificationEmailStatus] = useState(false)
	const [resentVerificationEmailSuccessText, setResentVerificationEmailSuccessText] = useState('')
	const [showResendVerificationEmailBar, setShowResendVerificationEmailBar] = useState(false)

	const dispatch = useDispatch()

	useEffect(() => {
		(async() => {
			if(isAuthenticated) {
				try {
					const token = await getIdTokenClaims()

					checkAndFetchImportantData(token.__raw)
				} catch(e) {
					console.error(e)
				}
			}
		})()
	}, [getIdTokenClaims, isAuthenticated])

	const [renderNotificationData, setRenderNotificationData] = useState([])

	useEffect(() => {
		if(!allNotifications) return

		 setRenderNotificationData(
			allNotifications.map((item, index) => (
				<div className={'cursor-pointer p-3 mb-2 hover:bg-gray-50 rounded-xl'}>
					<div className={'text-xs mb-1 text-gray-400 flex items-center justify-between'}>
						<div className={'font-medium text-sm text-gray-800 flex items-center gap-2'}>
							{
								item.is_read == 1 ? (
									<div className={'w-2 h-2 rounded-full bg-green-300'} />
								) : (
									<div className={'w-2 h-2 rounded-full bg-blue-300'} />
								)
							}
							{item.type}
						</div>
						<div>{item.created_at}</div>
					</div>
					<div className={'text-xs text-gray-400'}>
						{item.message}
					</div>
				</div>)
			)
		)
	}, [allNotifications])

	let checkedCount = 0

	const checkAndFetchImportantData = (token) => {
		checkAuthentication(token).then(res => {
			if(res.data.success) {
				if(res.data.user == null) {
					if(checkedCount < 15) {
						setTimeout(() => {
							checkedCount++
							checkAndFetchImportantData(token)
						}, 1000)
					} else {
						window.location.href = "/login"
					}
					return
				}
				setUsername(res.data.user.name)
				setEmail(res.data.user.email)

				checkUserVerificationStatus(token).then(res => {
					if(!res.data.verified) {
						setShowResendVerificationEmailBar(true)
					}
				})

				getNotificationCount(token).then(res => {
					setNotificationCount(res.data.notificationCount)
				})

				getTodayNotifications(token).then(res => {
					setTodayNotifications(res.data.notifications)
				})

				getAllNotifications(token).then(res => {
					setAllNotifications(res.data.notifications)
				})
			} else {
				window.location.href = '/login'
			}
		}).catch(err => {
			console.error(err)
		})
	}

	const handleMarkAllRead = async () => {
		setNotificationCount(0)
		const token = await getIdTokenClaims()

		readAllNotification(token.__raw).then(res => {
			if(res.data.success) {
				alert(res.data.message)
			} else {
				alert(res.data.error)
			}
		}).catch(err => {
			console.error(err)
		})
	}

	const handleLogout = () => {
		logout({
			returnTo:window.location.origin, 
		})
	}

	const handleResendVerifyEmail = async () => {
		try {
			setResendingEmailStatus(true)
			const token = await getIdTokenClaims()

			resendUserVerificationEmail(token.__raw, user.sub).then(res => {
				setResentVerificationEmailStatus(true)
				setResentVerificationEmailSuccessText(res.data.message)

				setTimeout(() => {
					setShowResendVerificationEmailBar(false)
				}, 5000)
			}).catch(err => {
				console.error(err)
			}).finally(() => {
				setResendingEmailStatus(false)
			})
		} catch(e) {
			console.error(e)
			setResendingEmailStatus(false)
		}
	}

	const nestedHeaderStore = useSelector(state => state.nestedHeaderReducer)
	const handleNestedHeaderUpdate = () => {
		dispatch(goBack());
		breadCrumb.pop();
		setBreadCrumb(breadCrumb);
		setTimeout(() => {
			console.log('nh', nestedHeaderStore)
			console.log("->", nestedHeaderStore[nestedHeaderStore.length - 2])
			if(nestedHeaderStore.length > 1) {
				setNestedHeader(nestedHeaderStore[nestedHeaderStore.length - 2])
			} else {
				dispatch(goBack()) // to clear the state
				setNestedHeader(null)
			}
		}, 100)
	};

	const breadCrumbGenerator = breadCrumb.map((item, index) => {
		return item.label + (index !== breadCrumb.length - 1 ? ' / ' : '').toString();
	})

    return <div>
		<div className={'flex text-center justify-center items-center border-b border-gray-200 py-2 w-full ' + (resentVerificationEmailStatus ? 'bg-green-500' : 'bg-red-500') + (showResendVerificationEmailBar ? '' : ' hidden')}>
			<p className={'mr-2 text-white ' + (resentVerificationEmailStatus ? 'hidden' : '')}>
				A verification email was sent to your email address. Please verify your email!
			</p>
			<p className={'mr-2 text-white ' + (resentVerificationEmailStatus ? '' : 'hidden')}>
				{resentVerificationEmailSuccessText}
			</p>
			<Button className={resentVerificationEmailStatus ? 'hidden': ''} onClick={handleResendVerifyEmail} loading={resendingEmailStatus}>Resend verification email</Button>
		</div>
		<div className={'flex justify-between items-center border-b border-gray-200 sm:px-8 p-3 w-full sm:py-4 sm:bg-white bg-brand-black'}>
			<div className={'hidden sm:block'}>
				<h2 className={'text-3xl font-medium'}>
					{nestedHeader ? <div className={'flex gap-3 items-center'}>
						<ReactSVG className={'p-3 cursor-pointer'} src={'/assets/icons/arrow-left.svg'} onClick={() => {handleNestedHeaderUpdate()}} />
						<div>
							{nestedHeader.header}
							{breadCrumb ? <div className={'text-xs text-gray-500 mt-1'}>
								{breadCrumbGenerator}
							</div> : ''}
						</div>
					</div> : getPageHeader(location)}
				</h2>
			</div>
			<div className={`w-86 h-8 pl-2 pt-2 pr-2 flex items-center justify-center sm:hidden flex`}>
				<img src={`/assets/logo/logo.png`} className={'w-[120px]'} alt="logo"/>
			</div>
			<div className={'flex items-center'}>

				<div className={'cursor-pointer sm:hidden flex mr-1'}>
					<ReactSVG src="/assets/icons/comment.svg" className={'text-white'} />
				</div>
				<Dropdown
					placement="bottomCenter"
					trigger={['click']}
					dropdownRender={(menu) => (
						<div className={'bg-white w-[26rem] border border-gray-100 rounded-lg shadow-lg h-[88vh]'}>
							<div className={'flex items-center justify-between p-3'}>
								<p className={'font-bold text-2xl'}>Notification</p>
								<div className={'flex items-center text-blue-600 text-sm gap-2 font-medium cursor-pointer'} onClick={() => {handleMarkAllRead()}}>
									<ReactSVG src={'/assets/icons/double-checks.svg'} />
									Mark all as read
								</div>
							</div>
							<div className={'flex items-end w-full bg-gray-100 pt-1 px-3 gap-3 border-t border-gray-200'}>
								<div className={'px-1 pb-1 font-bold border-b-2 border-blue-400'}>All</div>
							</div>
							<div className={'p-1 h-[78vh] overflow-y-auto'}>
								{renderNotificationData}
							</div>
						</div>
					)}
				>
					<div className={'text-white cursor-pointer sm:mr-8 mr-2 sm:hover:bg-gray-100 rounded-lg transition p-3 relative'}>
						<div className={'sm:flex hidden px-2 text-xs py-[3px] rounded-2xl bg-blue-600 text-white absolute top-0 -right-4'}>
							{notificationCount}
						</div>
						<div className={'sm:hidden flex text-xs w-5 h-5 rounded-2xl bg-yellow-400 absolute top-1 right-1 border-[4.5px] border-black'}>
						</div>
						<ReactSVG src="/assets/icons/bell.svg" className={'sm:text-black text-white'} />
					</div>
				</Dropdown>

				<Dropdown
					placement="bottomRight"
					trigger={['click']}
					overlayStyle={{
						minWidth: 'max-content'
					}}
					dropdownRender={(menu) => (
						<div className={'bg-white w-44 p-2 border border-gray-100 rounded-lg shadow-lg'}>
							<Link to={'/settings'} className={'flex items-center gap-2 hover:bg-gray-50 px-2 py-2 cursor-pointer'}>
								<ReactSVG src={'/assets/icons/profile.svg'} />
								Profile
							</Link>
							<div className={'flex items-center gap-2 hover:bg-gray-50 px-2 py-2 cursor-pointer text-rose-500'} onClick={handleLogout}>
								<ReactSVG src={'/assets/icons/logout.svg'} />
								Logout
							</div>
						</div>
					)}
				>
					<div className={'flex items-center cursor-pointer sm:mr-0 mr-[1.1rem]'}>
						<div className={'sm:flex hidden w-10 h-10 rounded-full bg-white-600 text-white justify-center items-center sm:mr-2'}>
							<img src="/assets/image/default-avatar.png" className={'rounded-full'} alt="user"/>
						</div>
						<div className={'sm:block hidden mr-4'}>
							<b className={'font-medium mb-1'}>{username}</b>
							<p className={'text-sm text-gray-500'}>{email}</p>
						</div>
						<div className={'sm:block hidden cursor-pointer'}>
							<ReactSVG src="/assets/icons/dropdown.svg" className={'text-black'} />
						</div>

						<div className={'cursor-pointer sm:hidden flex'}>
							<ReactSVG src="/assets/icons/user-place.svg" className={'text-white'} />
						</div>
					</div>
				</Dropdown>

				<div className={'cursor-pointer sm:hidden flex'} onClick={() => {setCollapseSideBar(!collapseSideBar)}}>
					<ReactSVG src="/assets/icons/burger.svg" className={'text-white'} />
				</div>
			</div>
		</div>
	</div>
}

export default TopNav;
