export const archiveData = [
    {
        key: '1',
        name: 'Test 19 July, 2024',
        size: '42kb',
        createdAt: '12 Aug, 2024 - 00:00:00',
    },
    {
        key: '1',
        name: 'Test 19 July, 2024',
        size: '42kb',
        createdAt: '12 Aug, 2024 - 00:00:00',
    },
    {
        key: '1',
        name: 'Test 19 July, 2024',
        size: '42kb',
        createdAt: '12 Aug, 2024 - 00:00:00',
    },
    {
        key: '1',
        name: 'Test 19 July, 2024',
        size: '42kb',
        createdAt: '12 Aug, 2024 - 00:00:00',
    },
    {
        key: '1',
        name: 'Test 19 July, 2024',
        size: '42kb',
        createdAt: '12 Aug, 2024 - 00:00:00',
    },
    {
        key: '1',
        name: 'Test 19 July, 2024',
        size: '42kb',
        createdAt: '12 Aug, 2024 - 00:00:00',
    },
];
