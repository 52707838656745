import { useAuth0 } from "@auth0/auth0-react";
import { Dropdown, Modal, Popover, Table } from "antd";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ReactSVG } from "react-svg";
import { useContextState } from "../../../context/ContextProvider";
import { addToNestedHeader } from "../../../features/nested_header/nested_header";
import AddAccountModal from "../../../modals/AddAccountModal";
import AddRestoreOrgModal from "../../../modals/AddRestoreOrgModal";
import ConfirmModal from "../../../modals/ConfirmModal";
import DeleteOrgModal from "../../../modals/DeleteOrgModal";
import DisconnectOrgModal from "../../../modals/DisconnectOrgModal";
import SuccessModal from "../../../modals/SuccessModal";
import AddButton from "../../../shared/AddButton";
import { SERVER_URL } from "../../../utils/axios.js";
import {
  addOrg,
  getOrganizations,
  subUpdate,
  xeroClearData,
} from "../../../utils/organization";
import { organisationPage } from "../constants";
import "./accounts.scss";

const Accounts = ({ onOrgIdChange }) => {
  const {
    isAuthenticated,
    isLoading,
    getIdTokenClaims,
    getAccessTokenSilently,
  } = useAuth0();
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      if (!isAuthenticated && !isLoading) {
        getAccessTokenSilently()
          .then(() => {})
          .catch(() => {
            navigate("/login");
          });
      }
    })();
  }, [isAuthenticated, isLoading]);

  const { setBreadCrumb } = useContextState();
  const { setNestedHeader } = useContextState();
  const [hoveredRow, setHoveredRow] = useState(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedOrgAction, setSelectedOrgAction] = useState(false);
  const [connectOrgModalOpen, setConnectOrgModalOpen] = useState(false);

  const [addAccModalOpen, setAddAccModalOpen] = useState(false);
  const [removeOrgModalOpen, setRemoveOrgModalOpen] = useState(false);
  const [unsubOrgModalOpen, setUnsubOrgModalOpen] = useState(false);
  const [restoreOrgSelected, setRestoreOrgSelected] = useState("");
  const [restoreOrgIdSelected, setRestoreOrgIdSelected] = useState();
  const [restoreOrgBackupDates, setRestoreOrgBackupDates] = useState([]);
  const [deleteOrgModalOpen, setDeleteOrgModalOpen] = useState(false);
  const [deleteOrgSelected, setDeleteOrgSelected] = useState();
  const [deleteOrgIdSelected, setDeleteOrgIdSelected] = useState(null);

  const [organizations, setOrganizations] = useState([]);
  const [backupStatus, setBackupStatus] = useState({});
  const [restoreStatus, setRestoreStatus] = useState({});

  const [tableDataLoading, setTableDataLoading] = useState(false);

  const [openedIndex, setOpenedIndex] = useState(0);
  const [successTitle, setSuccessTitle] = useState("");
  const [successText, setSuccessText] = useState("");
  const [successModalOpen, setSuccessModalOpen] = useState(false);
  const [successModalCloseHandler, setSuccessModalCloseHandler] = useState(
    () => () => {
      setSuccessModalOpen(false);
    }
  );

  const [confirmTitle, setConfirmTitle] = useState("");
  const [confirmText, setConfirmText] = useState("");
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [confirmOnCancel, setConfirmOnCancel] = useState(() => () => {
    setConfirmModalOpen(false);
  });
  const [confirmOnOkay, setConfirmOnOkay] = useState(() => () => {});
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isLoading) {
      fetchOrgs();
    }
  }, [isLoading, getIdTokenClaims]);

  const fetchOrgs = async () => {
    try {
      const token = await getIdTokenClaims();

      setTableDataLoading(true);
      getOrganizations(token.__raw)
        .then((res) => {
          setOrganizations(res.data.accounts);
          setBackupStatus(res.data.progress);
          setRestoreStatus(res.data.restoreProgress);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setTableDataLoading(false);
        });
    } catch (e) {
      console.error(e);
    }
  };

  const onRestoreModalClose = () => {
    setRestoreOrgSelected("");
    setRestoreOrgIdSelected("");
  };

  const handleAddNewOrg = () => {
    (async () => {
      try {
        let token = await getIdTokenClaims();

        addOrg(token.__raw);
      } catch (e) {}
    })();
  };

  const removeDataFromTable = () => {
    setOrganizations([]);
  };

  const handleOrgDeleted = () => {
    setDeleteOrgIdSelected(null);
    setSelectedRowKeys([]);
    removeDataFromTable();
    fetchOrgs();
    setDeleteOrgModalOpen(false);
  };

  const handleSubscribeUpdate = async () => {
    try {
      const token = await getIdTokenClaims();

      subUpdate(token.__raw, selectedOrgAction)
        .then((res) => {
          if (res.data.success) {
            setSuccessTitle("Unsubscribed");
            setSuccessText(
              "You have successfully unsubscribed the organization!"
            );
            setSuccessModalOpen(true);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    } catch (e) {
      console.error(e);
    }
  };

  const handleXeroConnect = async () => {
    try {
      await getAccessTokenSilently();

      const token = await getIdTokenClaims();

      window.location.href =
        SERVER_URL +
        "/connect/xero?id=" +
        selectedOrgAction +
        "&token=" +
        token.__raw;

      // connectXero(token.__raw, selectedOrgAction).then(res => {
      // 	if(res.data.success) {
      // 		setSuccessModalOpen(true)
      // 		setSuccessTitle("Connected")
      // 		setSuccessText("You have successfully connected the organization!")
      // 		setSuccessModalCloseHandler(() => {
      // 			removeDataFromTable()
      // 			fetchOrgs()
      // 		})
      // 	}
      // }).catch(err => {
      // 	console.error(err)
      // })
    } catch (e) {
      console.error(e);
    }
  };

  const handleDisconnected = async () => {
    try {
      const token = await getIdTokenClaims();

      window.location.href =
        SERVER_URL +
        "/xero-disconnect?id=" +
        selectedOrgAction +
        "&token=" +
        token.__raw;
    } catch (e) {
      console.error(e);
    }
  };

  const handleClearExistingBackup = (id) => {
    setConfirmModalOpen(true);
    setConfirmTitle("Clear existing backup");
    setConfirmText(
      "Do you want to clear existing backup data for the organization?"
    );
    setConfirmOnOkay(() => () => {
      clearExistingBackup(id);
      setConfirmModalOpen(false);
    });
  };

  const clearExistingBackup = async (id) => {
    try {
      const token = await getIdTokenClaims();

      setTableDataLoading(true);

      xeroClearData(token.__raw, id)
        .then((res) => {
          if (res.data.success) {
            setSuccessModalOpen(true);
            setSuccessTitle("Cleared existing backup");
            setSuccessText(
              "Successfully cleared the backup data for the organization"
            );

            fetchOrgs();
          }
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          setTableDataLoading(false);
        });
    } catch (e) {
      console.error(e);
    }
  };

  const handleRestoreSubmit = () => {
    setSuccessTitle("Restore Queueed");
    setSuccessText("You have successfully added restore for the organization");

    setSuccessModalOpen(true);

    setSuccessModalCloseHandler(() => () => {
      fetchOrgs();
    });
  };

  const popupContent = (id, type = "backup") => {
    let progress = null;
    if (type == "backup") {
      if (backupStatus) {
        progress = backupStatus[id];
      }
    } else {
      if (restoreStatus) {
        progress = restoreStatus[id];
      }
    }

    if (progress == null || (progress && progress.moduleStatus == null)) {
      return <div>N/A</div>;
    }
    const moduleStatus = progress.moduleStatus;

    return (
      <div className={"lg:flex w-full gap-5"}>
        <div className={"lg:border-r border-gray-200 lg:pr-5"}>
          <div
            className={
              "flex justify-between border-b border-gray-300 p-1 items-center text-gray-600 status-icons  gap-2" +
              (moduleStatus.accounts ? "" : " hidden")
            }
          >
            <div>Accounts</div>
            <div>{printStatusIcon(moduleStatus.accounts)}</div>
          </div>
          <div
            className={
              "flex justify-between border-b border-gray-300 p-1 items-center text-gray-600 status-icons  gap-2" +
              (moduleStatus.balance_sheet ? "" : " hidden")
            }
          >
            <div>Balance Sheet</div>
            <div>{printStatusIcon(moduleStatus.balance_sheet)}</div>
          </div>
          <div
            className={
              "flex justify-between border-b border-gray-300 p-1 items-center text-gray-600 status-icons  gap-2" +
              (moduleStatus.batch_payments ? "" : " hidden")
            }
          >
            <div>Batch Payments</div>
            <div>{printStatusIcon(moduleStatus.batch_payments)}</div>
          </div>
          <div
            className={
              "flex justify-between border-b border-gray-300 p-1 items-center text-gray-600 status-icons  gap-2" +
              (moduleStatus.branding_themes ? "" : " hidden")
            }
          >
            <div>Branding Themes</div>
            <div>{printStatusIcon(moduleStatus.branding_themes)}</div>
          </div>
          <div
            className={
              "flex justify-between border-b border-gray-300 p-1 items-center text-gray-600 status-icons  gap-2" +
              (moduleStatus.contacts ? "" : " hidden")
            }
          >
            <div>Contacts</div>
            <div>{printStatusIcon(moduleStatus.contacts)}</div>
          </div>
          <div
            className={
              "flex justify-between border-b border-gray-300 p-1 items-center text-gray-600 status-icons  gap-2" +
              (moduleStatus.credit_notes ? "" : " hidden")
            }
          >
            <div>Credit Notes</div>
            <div>{printStatusIcon(moduleStatus.credit_notes)}</div>
          </div>
          <div
            className={
              "flex justify-between border-b border-gray-300 p-1 items-center text-gray-600 status-icons  gap-2" +
              (moduleStatus.currencies ? "" : " hidden")
            }
          >
            <div>Currencies</div>
            <div>{printStatusIcon(moduleStatus.currencies)}</div>
          </div>
          <div
            className={
              "flex justify-between border-b border-gray-300 p-1 items-center text-gray-600 status-icons  gap-2" +
              (moduleStatus.invoices ? "" : " hidden")
            }
          >
            <div>Invoices</div>
            <div>{printStatusIcon(moduleStatus.invoices)}</div>
          </div>
          <div
            className={
              "flex justify-between border-b border-gray-300 p-1 items-center text-gray-600 status-icons  gap-2" +
              (moduleStatus.items ? "" : " hidden")
            }
          >
            <div>Items</div>
            <div>{printStatusIcon(moduleStatus.items)}</div>
          </div>
          <div
            className={
              "flex justify-between border-b border-gray-300 p-1 items-center text-gray-600 status-icons  gap-2" +
              (moduleStatus.journals ? "" : " hidden")
            }
          >
            <div>Journals</div>
            <div>{printStatusIcon(moduleStatus.journals)}</div>
          </div>
          <div
            className={
              "flex justify-between border-b border-gray-300 p-1 items-center text-gray-600 status-icons  gap-2" +
              (moduleStatus.linked_transactions ? "" : " hidden")
            }
          >
            <div>Linked Transactions</div>
            <div>{printStatusIcon(moduleStatus.linked_transactions)}</div>
          </div>

          <div
            className={
              "flex justify-between border-b border-gray-300 p-1 items-center text-gray-600 status-icons  gap-2" +
              (moduleStatus.manual_journals ? "" : " hidden")
            }
          >
            <div>Manual Journals</div>
            <div>{printStatusIcon(moduleStatus.manual_journals)}</div>
          </div>

          <div
            className={
              "flex justify-between border-b border-gray-300 p-1 items-center text-gray-600 status-icons  gap-2" +
              (moduleStatus.organisations ? "" : " hidden")
            }
          >
            <div>Organisations</div>
            <div>{printStatusIcon(moduleStatus.organisations)}</div>
          </div>
        </div>

        <div>
          <div
            className={
              "flex justify-between border-b border-gray-300 p-1 items-center text-gray-600 status-icons  gap-2" +
              (moduleStatus.payments ? "" : " hidden")
            }
          >
            <div>Payments</div>
            <div>{printStatusIcon(moduleStatus.payments)}</div>
          </div>

          <div
            className={
              "flex justify-between border-b border-gray-300 p-1 items-center text-gray-600 status-icons  gap-2" +
              (moduleStatus.pre_payments ? "" : " hidden")
            }
          >
            <div>Pre-payments</div>
            <div>{printStatusIcon(moduleStatus.pre_payments)}</div>
          </div>

          <div
            className={
              "flex justify-between border-b border-gray-300 p-1 items-center text-gray-600 status-icons  gap-2" +
              (moduleStatus.profit_and_loss ? "" : " hidden")
            }
          >
            <div>Profit & Loss</div>
            <div>{printStatusIcon(moduleStatus.profit_and_loss)}</div>
          </div>

          <div
            className={
              "flex justify-between border-b border-gray-300 p-1 items-center text-gray-600 status-icons  gap-2" +
              (moduleStatus.purchase_orders ? "" : " hidden")
            }
          >
            <div>Purchase Orders</div>
            <div>{printStatusIcon(moduleStatus.purchase_orders)}</div>
          </div>

          <div
            className={
              "flex justify-between border-b border-gray-300 p-1 items-center text-gray-600 status-icons  gap-2" +
              (moduleStatus.quotes ? "" : " hidden")
            }
          >
            <div>Quotes</div>
            <div>{printStatusIcon(moduleStatus.quotes)}</div>
          </div>

          <div
            className={
              "flex justify-between border-b border-gray-300 p-1 items-center text-gray-600 status-icons  gap-2" +
              (moduleStatus.repeating_invoices ? "" : " hidden")
            }
          >
            <div>Repeating Invoices</div>
            <div>{printStatusIcon(moduleStatus.repeating_invoices)}</div>
          </div>

          <div
            className={
              "flex justify-between border-b border-gray-300 p-1 items-center text-gray-600 status-icons  gap-2" +
              (moduleStatus.tax_rates ? "" : " hidden")
            }
          >
            <div>Tax Rates</div>
            <div>{printStatusIcon(moduleStatus.tax_rates)}</div>
          </div>

          <div
            className={
              "flex justify-between border-b border-gray-300 p-1 items-center text-gray-600 status-icons  gap-2" +
              (moduleStatus.tracking_categories ? "" : " hidden")
            }
          >
            <div>Tracking Categories</div>
            <div>{printStatusIcon(moduleStatus.tracking_categories)}</div>
          </div>

          <div
            className={
              "flex justify-between border-b border-gray-300 p-1 items-center text-gray-600 status-icons  gap-2" +
              (moduleStatus.transactions ? "" : " hidden")
            }
          >
            <div>Transactions</div>
            <div>{printStatusIcon(moduleStatus.transactions)}</div>
          </div>

          <div
            className={
              "flex justify-between border-b border-gray-300 p-1 items-center text-gray-600 status-icons  gap-2" +
              (moduleStatus.transfers ? "" : " hidden")
            }
          >
            <div>Transfers</div>
            <div>{printStatusIcon(moduleStatus.transfers)}</div>
          </div>

          <div
            className={
              "flex justify-between border-b border-gray-300 p-1 items-center text-gray-600 status-icons  gap-2" +
              (moduleStatus.trial_balance ? "" : " hidden")
            }
          >
            <div>Trial Balance</div>
            <div>{printStatusIcon(moduleStatus.trial_balance)}</div>
          </div>

          <div
            className={
              "flex justify-between border-b border-gray-300 p-1 items-center text-gray-600 status-icons  gap-2" +
              (moduleStatus.users ? "" : " hidden")
            }
          >
            <div>Users</div>
            <div>{printStatusIcon(moduleStatus.users)}</div>
          </div>
        </div>
      </div>
    );
  };

  const printStatusIcon = (status) => {
    if (!status) return;
    if (status.toLowerCase() == "completed") {
      return (
        <ReactSVG
          src={"/assets/icons/green-check.svg"}
          className={"text-green-500"}
        />
      );
    } else if (status.toLowerCase() == "pending") {
      return (
        <ReactSVG
          src={"/assets/icons/loader.svg"}
          className={"text-green-500"}
        />
      );
    }
  };

  const printRestoreStatus = (status) => {
    if (status == "pending") {
      return "Restore pending";
    } else if (status == "running") {
      return "Restore in progress";
    } else if (status == "completed") {
      return "Restore completed";
    }
  };

  const columns = [
    {
      title: "Organization",
      dataIndex: "account",
      key: "account",
      width: "30%",
      render: (_, { id, account }) => {
        if (account)
          return (
            <div
              onClick={() => {
                const nextPage = {
                  header: account,
                  page: organisationPage.ORGANISATION_FOLDER,
                };
                dispatch(addToNestedHeader(nextPage));
                setNestedHeader(nextPage);
                setBreadCrumb([]);
                setBreadCrumb((prevState) => [
                  ...prevState,
                  { label: account, url: window.location.href },
                ]);

                onOrgIdChange(id);
              }}
              className={"cursor-pointer"}
            >
              {account}
            </div>
          );
      },
    },
    {
      title: "Subscription",
      dataIndex: "subscription",
      key: "subscription",
      render: (_, { subscription }) => {
        if (subscription)
          return (
            <div className={"text-gray-500 flex gap-2 items-center"}>
              {subscription == 1 ? (
                <ReactSVG
                  src={"/assets/icons/green-check.svg"}
                  className={"text-green-500"}
                />
              ) : (
                ""
              )}
              {subscription == 1 ? "Subscribed" : "Not subscribed"}
            </div>
          );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (_, { id, status, key, subscription }) => {
        return (
          <div className={"flex justify-content-center gap-4"}>
            <div className={"text-gray-500 flex gap-2 items-center"}>
              {status.map((item, index) => (
                <Popover content={popupContent(id)} title="Status">
                  <div className={"flex items-center"}>
                    {index > 0 && <div className={"mx-4 text-gray-300"}>|</div>}
                    <div
                      className={`h-2 w-2 rounded-full mr-2 ${
                        item?.failed
                          ? "bg-red-300"
                          : item?.progress
                          ? "bg-orange-300"
                          : "bg-green-300"
                      }`}
                    />
                    {item?.message}
                  </div>
                </Popover>
              ))}
            </div>
            <div
              className={
                "text-gray-500 flex gap-2 items-center " +
                (restoreStatus[id] ? "" : "hidden")
              }
            >
              <Popover content={popupContent(id, "restore")} title="Status">
                <div className={"flex items-center"}>
                  <div
                    className={`h-2 w-2 rounded-full mr-2 ${
                      restoreStatus[id]
                        ? restoreStatus[id].restoreStatus == "completed"
                          ? "bg-green-300"
                          : "bg-orange-300"
                        : ""
                    }`}
                  />
                  {restoreStatus[id] &&
                    printRestoreStatus(restoreStatus[id].restoreStatus)}
                </div>
              </Popover>
            </div>
          </div>
        );
      },
    },
    {
      title: "Creation Date",
      dataIndex: "date",
      key: "date",
      width: "20%",
      render: (_, { id, account, key, date, org_status, backup_dates }) => (
        <div className={"relative"}>
          {date}
          {hoveredRow === key ? (
            <div
              className={
                "absolute right-0 z-10 w-1/4 flex justify-between items-center -top-[4px]"
              }
            >
              <Dropdown
                placement="bottomRight"
                trigger={["click"]}
                dropdownRender={(menu) => (
                  <div
                    className={
                      "bg-white w-60 p-2 border border-gray-100 rounded-lg shadow-lg"
                    }
                  >
                    {org_status == "1" ? (
                      <span>
                        <div
                          className={
                            "font-medium flex items-center gap-2 rounded-lg hover:bg-gray-50 p-2 text-sm cursor-pointer"
                          }
                          onClick={() => {
                            setRestoreOrgSelected(account);
                            setRestoreOrgIdSelected(id);
                            setRestoreOrgBackupDates(backup_dates);
                          }}
                        >
                          <ReactSVG src={"/assets/icons/arrow-clockwise.svg"} />
                          Restore
                        </div>

                        <div
                          className={
                            "font-medium flex items-center gap-2 rounded-lg hover:bg-gray-50 p-2 text-sm cursor-pointer"
                          }
                          onClick={() => {
                            setRemoveOrgModalOpen(true);
                            setSelectedOrgAction(id);
                          }}
                        >
                          <ReactSVG src={"/assets/icons/xero.svg"} />
                          Disconnect from Xero
                        </div>
                      </span>
                    ) : (
                      <div
                        className={
                          "font-medium flex items-center gap-2 rounded-lg hover:bg-gray-50 p-2 text-sm cursor-pointer"
                        }
                        onClick={() => {
                          setConnectOrgModalOpen(true);
                          setSelectedOrgAction(id);
                        }}
                      >
                        <ReactSVG src={"/assets/icons/xero.svg"} />
                        Connect with Xero
                      </div>
                    )}

                    <div
                      className={
                        "font-medium flex items-center gap-2 rounded-lg hover:bg-gray-50 p-2 text-sm cursor-pointer"
                      }
                      onClick={() => handleClearExistingBackup(id)}
                    >
                      <ReactSVG src={"/assets/icons/broom.svg"} />
                      Clear Existing Backup Data
                    </div>

                    <div
                      className={
                        "font-medium flex items-center gap-2  text-rose-500 rounded-lg hover:bg-gray-50 p-2 text-sm cursor-pointer"
                      }
                      onClick={() => {
                        setDeleteOrgModalOpen(true);
                        setDeleteOrgSelected(account);
                        setDeleteOrgIdSelected(Number(id));
                      }}
                    >
                      <ReactSVG src={"/assets/icons/delete.svg"} />
                      Delete
                    </div>
                  </div>
                )}
              >
                <ReactSVG
                  className={"p-1 cursor-pointer rounded-lg hover:bg-white"}
                  src={"/assets/icons/dot-menu.svg"}
                />
              </Dropdown>
            </div>
          ) : null}
        </div>
      ),
    },
  ];

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedKeys) => {
      setSelectedRowKeys(selectedKeys);
    },
  };

  const mobileRender = organizations.map((item, index) => {
    return (
      <div
        key={index}
        className={
          " border rounded-lg border-gray-300 text-sm py-3 mt-3 px-4 text-gray-500"
        }
        onClick={() => {
          setOpenedIndex(index);
        }}
      >
        <div className={"flex items-center justify-between cursor-pointer"}>
          <div className={"flex items-center gap-2"}>
            <ReactSVG
              src="/assets/icons/common-dropdown.svg"
              className={`text-gray-700 w-6 h-6 flex items-center justify-center bg-gray-100 rounded-full ${
                openedIndex === index && "rotate-180"
              }`}
            />
            <div className={" text-gray-500 font-medium"}>{item.account}</div>
          </div>
          <div className={"flex justify-end items-center gap-2"}>
            {item.status.length ? (
              <div className={"text-gray-500 flex gap-2 items-center"}>
                {item.status.map((item, index) => (
                  <Popover content={popupContent(item.id)} title="Status">
                    <div className={"flex items-center"}>
                      {index > 0 && (
                        <div className={"mx-4 text-gray-300"}>|</div>
                      )}
                      <div
                        className={`h-2 w-2 rounded-full mr-2 ${
                          item?.failed
                            ? "bg-red-300"
                            : item?.progress
                            ? "bg-orange-300"
                            : "bg-green-300"
                        }`}
                      />
                      {item?.message}
                    </div>
                  </Popover>
                ))}
              </div>
            ) : (
              ""
            )}
            <div
              className={
                "right-0 z-10 flex justify-between items-center -top-[4px]"
              }
            >
              <Dropdown
                placement="bottomRight"
                trigger={["click"]}
                dropdownRender={(menu) => (
                  <div
                    className={
                      "bg-white w-60 p-2 border border-gray-100 rounded-lg shadow-lg"
                    }
                  >
                    {item.org_status == "1" ? (
                      <span>
                        <div
                          className={
                            "font-medium flex items-center gap-2 rounded-lg hover:bg-gray-50 p-2 text-sm cursor-pointer"
                          }
                          onClick={() => {
                            setRestoreOrgSelected(item.account);
                            setRestoreOrgIdSelected(item.id);
                            setRestoreOrgBackupDates(item.backup_dates);
                          }}
                        >
                          <ReactSVG src={"/assets/icons/arrow-clockwise.svg"} />
                          Restore
                        </div>

                        <div
                          className={
                            "font-medium flex items-center gap-2 rounded-lg hover:bg-gray-50 p-2 text-sm cursor-pointer"
                          }
                          onClick={() => {
                            setRemoveOrgModalOpen(true);
                            setSelectedOrgAction(item.id);
                          }}
                        >
                          <ReactSVG src={"/assets/icons/xero.svg"} />
                          Disconnect from Xero
                        </div>
                      </span>
                    ) : (
                      <div
                        className={
                          "font-medium flex items-center gap-2 rounded-lg hover:bg-gray-50 p-2 text-sm cursor-pointer"
                        }
                        onClick={() => {
                          setConnectOrgModalOpen(true);
                          setSelectedOrgAction(item.id);
                        }}
                      >
                        <ReactSVG src={"/assets/icons/xero.svg"} />
                        Connect with Xero
                      </div>
                    )}

                    <div
                      className={
                        "font-medium flex items-center gap-2 rounded-lg hover:bg-gray-50 p-2 text-sm cursor-pointer"
                      }
                      onClick={() => handleClearExistingBackup(item.id)}
                    >
                      <ReactSVG src={"/assets/icons/broom.svg"} />
                      Clear Existing Backup Data
                    </div>

                    <div
                      className={
                        "font-medium flex items-center gap-2  text-rose-500 rounded-lg hover:bg-gray-50 p-2 text-sm cursor-pointer"
                      }
                      onClick={() => {
                        setDeleteOrgModalOpen(true);
                        setDeleteOrgSelected(item.account);
                        setDeleteOrgIdSelected(Number(item.id));
                      }}
                    >
                      <ReactSVG src={"/assets/icons/delete.svg"} />
                      Delete
                    </div>
                  </div>
                )}
              >
                <ReactSVG
                  className={"p-1 cursor-pointer rounded-lg hover:bg-white"}
                  src={"/assets/icons/dot-menu.svg"}
                />
              </Dropdown>
            </div>
          </div>
        </div>
        {openedIndex === index && (
          <div className={"mt-2"}>
            <div className="flex justify-between">
              <div>Creation Date:</div>
              <div>{item.date}</div>
            </div>
            <div className="flex justify-between mt-1">
              <div>Subscription:</div>
              <div>
                <div className={"text-gray-500 flex gap-2 items-center"}>
                  {item.subscription == 1 ? (
                    <ReactSVG
                      src={"/assets/icons/green-check.svg"}
                      className={"text-green-500"}
                    />
                  ) : (
                    ""
                  )}
                  {item.subscription == 1 ? "Subscribed" : "Not subscribed"}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  });

  const onDelete = () => {
    const orgIds = organizations
      .filter((org) => selectedRowKeys.includes(org.key))
      .map((org) => Number(org.id));

    setDeleteOrgIdSelected(orgIds);
    setDeleteOrgModalOpen(true);
  };

  return (
    <div className={"px-8"}>
      <div className={"flex items-center justify-between"}>
        {selectedRowKeys.length ? (
          <button
            onClick={onDelete}
            className={
              "flex gap-2 rounded-lg items-center px-2 font-bold py-1.5 border border-gray-300 cursor-pointer text-sm text-gray-700"
            }
          >
            <ReactSVG src="/assets/icons/delete.svg" />
            Delete
          </button>
        ) : (
          <div></div>
        )}
        <div className={"flex items-center gap-5 w-[32%] justify-end"}>
          <AddButton
            text={"Add New Organization"}
            clickMethod={handleAddNewOrg}
          />
        </div>
      </div>

      <Modal
        title={[<div className={"text-2xl"}>Add Xero Account</div>]}
        centered
        open={addAccModalOpen}
        footer={[
          <div className={"flex items-center justify-end gap-3"}>
            <div
              key="cancel"
              className={
                "px-2 text-sm py-1.5 border border-gray-200 rounded-lg"
              }
              onClick={() => setAddAccModalOpen(false)}
            >
              Cancel
            </div>

            <div
              key="submit"
              className={
                "px-2 text-sm py-1.5 bg-blue-500 text-white rounded-lg"
              }
              onClick={() => handleAddNewOrg}
            >
              Confirm
            </div>
          </div>,
        ]}
        onOk={() => setAddAccModalOpen(false)}
        onCancel={() => setAddAccModalOpen(false)}
      >
        <AddAccountModal />
      </Modal>

      <SuccessModal
        title={successTitle}
        text={successText}
        modalOpen={successModalOpen}
        onModalClose={successModalCloseHandler}
      />

      <Modal
        title={[<div className={"text-2xl"}>Disconnect Organization</div>]}
        centered
        open={removeOrgModalOpen}
        footer={[
          <div className={"flex items-center justify-end gap-3"}>
            <div
              key="cancel"
              className={
                "px-2 text-sm py-1.5 border border-gray-200 rounded-lg"
              }
              onClick={() => setRemoveOrgModalOpen(false)}
            >
              Cancel
            </div>

            <div
              key="submit"
              className={
                "px-2 text-sm py-1.5 bg-blue-500 text-white rounded-lg"
              }
              onClick={() => {
                setRemoveOrgModalOpen(false);
                handleDisconnected();
              }}
            >
              Confirm
            </div>
          </div>,
        ]}
        onOk={() => setRemoveOrgModalOpen(false)}
        onCancel={() => setRemoveOrgModalOpen(false)}
      >
        <DisconnectOrgModal organizationName={"Xero Organization"} />
      </Modal>

      <Modal
        title={[<div className={"text-2xl"}>Unsubscribe organization</div>]}
        centered
        open={unsubOrgModalOpen}
        footer={[
          <div className={"flex items-center justify-end gap-3"}>
            <div
              key="cancel"
              className={
                "px-2 text-sm py-1.5 border border-gray-200 rounded-lg"
              }
              onClick={() => setUnsubOrgModalOpen(false)}
            >
              Cancel
            </div>

            <div
              key="submit"
              className={
                "px-2 text-sm py-1.5 bg-blue-500 text-white rounded-lg cursor-pointer"
              }
              onClick={() => {
                setUnsubOrgModalOpen(false);
                handleSubscribeUpdate();
              }}
            >
              Confirm
            </div>
          </div>,
        ]}
        onOk={() => {
          setUnsubOrgModalOpen(false);
          handleSubscribeUpdate();
        }}
        onCancel={() => setUnsubOrgModalOpen(false)}
      >
        <div className={"py-8 text-gray-500 text-sm border-b border-gray-300"}>
          <div>Are you sure you want to unsubscribe the organization?</div>
        </div>
      </Modal>

      <Modal
        title={[<div className={"text-2xl"}>Connect organization</div>]}
        centered
        open={connectOrgModalOpen}
        footer={[
          <div className={"flex items-center justify-end gap-3"}>
            <div
              key="cancel"
              className={
                "px-2 text-sm py-1.5 border border-gray-200 rounded-lg"
              }
              onClick={() => setConnectOrgModalOpen(false)}
            >
              Cancel
            </div>

            <div
              key="submit"
              className={
                "px-2 text-sm py-1.5 bg-blue-500 text-white rounded-lg cursor-pointer"
              }
              onClick={() => {
                setConnectOrgModalOpen(false);
                handleXeroConnect();
              }}
            >
              Confirm
            </div>
          </div>,
        ]}
        onOk={() => {
          setConnectOrgModalOpen(false);
          handleXeroConnect();
        }}
        onCancel={() => setConnectOrgModalOpen(false)}
      >
        <div className={"py-8 text-gray-500 text-sm border-b border-gray-300"}>
          <div>Are you sure you want to connect the organization?</div>
        </div>
      </Modal>

      <AddRestoreOrgModal
        organizationId={restoreOrgIdSelected}
        organizationName={restoreOrgSelected}
        organizations={organizations}
        backupDates={restoreOrgBackupDates}
        onModalClose={onRestoreModalClose}
        onRestoreSubmit={handleRestoreSubmit}
      />

      <DeleteOrgModal
        orgId={deleteOrgIdSelected}
        organizationName={deleteOrgSelected}
        modalStatus={deleteOrgModalOpen}
        setModalStatus={setDeleteOrgModalOpen}
        onModalClose={() => {
          setDeleteOrgIdSelected(null);
          setDeleteOrgModalOpen(false);
        }}
        onSuccess={handleOrgDeleted}
      />

      <div className={"mt-6 mb-10 pb-4 lg:block hidden"}>
        <Table
          onRow={(record) => ({
            onMouseEnter: () => setHoveredRow(record.key),
            onMouseLeave: () => setHoveredRow(null),
          })}
          dataSource={organizations}
          rowSelection={rowSelection}
          columns={columns}
          loading={tableDataLoading || isLoading}
        />
      </div>

      <ConfirmModal
        title={confirmTitle}
        text={confirmText}
        modalOpen={confirmModalOpen}
        onCancel={confirmOnCancel}
        onOkay={confirmOnOkay}
      />

      <div className={"lg:hidden block"}>{mobileRender}</div>
    </div>
  );
};

export default Accounts;
