import {configureStore} from '@reduxjs/toolkit'
import authReducer from './features/auth/auth.js'
import nestedHeaderReducer from './features/nested_header/nested_header.js'

export default configureStore({
	reducer: {
		authReducer,
		nestedHeaderReducer,
	}
})
