import {createSlice} from '@reduxjs/toolkit'

const nestedHeaderSlice = createSlice({
	name: "nestedHeader",
	initialState: [],
	reducers: {
		goBack(state) {
			state.pop()
		},
		addToNestedHeader(state, action) {
			state.push(action.payload)
		}
	}
})

export const {goBack, addToNestedHeader} = nestedHeaderSlice.actions
export default nestedHeaderSlice.reducer
