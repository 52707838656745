import { useAuth0 } from "@auth0/auth0-react";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { Button, Modal } from "antd";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { commonStore } from "../../store/CommonStore";
import { addPaymentMethods } from "../../utils/payment_methods";

const AddCardModal = observer(({ modalOpen, onCreate }) => {
  const stripe = useStripe();
  const elements = useElements();

  const { getIdTokenClaims } = useAuth0();
  const [makeDefault, setMakeDefault] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();

    setIsSubmitting(true);

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded
      console.log("Stripe.js has not yet loaded");
      setIsSubmitting(false);
      return;
    }

    const cardElement = elements.getElement(CardElement);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
    });

    if (error) {
      console.error(error);
      document.getElementById("card-errors").textContent = error.message;
      setIsSubmitting(false);
    } else {
      console.log("PaymentMethod:", paymentMethod.id);
      handlePaymentMethod(paymentMethod.id); // Send the PaymentMethod ID to your server
      // Clear the CardElement input after a successful submission
      cardElement.clear();
    }
  };

  const handlePaymentMethod = async (paymentMethodId) => {
    try {
      const token = await getIdTokenClaims();

      addPaymentMethods(token.__raw, paymentMethodId, makeDefault)
        .then((res) => {
          if (res.data.success) {
            commonStore.setAddCardModalOpen(false);
            onCreate(res.data.message);
          } else {
            alert(res.data.message);
            console.error(res.data.message);
          }
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          setIsSubmitting(false);
        });
    } catch (e) {
      console.error(e);
      setIsSubmitting(false);
    }
  };

  // <input className={'w-full bg-gray-100 rounded-lg border-gray-200 border p-2'} type={'text'} placeholder={'eg. 4564-7890-6758-5647'} />
  //
  return (
    <Modal
      title={[<div className={"text-2xl"}>Add Payment Method</div>]}
      centered
      open={commonStore.addCardModalOpen}
      onCancel={() => commonStore.setAddCardModalOpen(false)}
      footer={null}
    >
      <form
        id="payment-form"
        onSubmit={(e) => {
          handleSubmit(e);
        }}
      >
        <div className={"py-6 text-sm border-b border-gray-300 w-full"}>
          <div className={"w-full mb-6"}>
            <p className={"font-medium text-sm mb-2"}>Card Details</p>

            <div id="card-element">
              <CardElement />
            </div>

            <div id="card-errors" role="alert"></div>
          </div>

          <label className={"w-full flex items-center gap-3 mb-6"}>
            <input
              type="checkbox"
              value="1"
              onChange={(e) => {
                setMakeDefault(e.target.checked);
              }}
            />
            Mark this default payment
          </label>

          <div className={"flex justify-end"}>
            <Button
              type="primary"
              htmlType="submit"
              loading={isSubmitting}
              className={
                "px-2 text-sm py-1.5 bg-blue-600 text-white rounded-lg"
              }
            >
              Add Payment Method
            </Button>
          </div>
        </div>
      </form>
    </Modal>
  );
});

export default AddCardModal;
