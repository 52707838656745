import {ReactSVG} from "react-svg";
import {Dropdown, Pagination, Table, DatePicker, Button, Menu, Checkbox} from "antd";
import { DownOutlined } from '@ant-design/icons';
import Filter from "../../shared/Filter";
import Search from "../../shared/Search";
import {auditLogData} from "./constants";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import {useCallback, useEffect, useState} from "react";
import {getLogs} from "../../utils/audit_log";
import {debounce} from "lodash";
import {CheckboxDropdown} from "./CheckboxDropdown";
import {getOrganizations} from "../../utils/organization";
import SuccessModal from "../../modals/SuccessModal";

const { RangePicker } = DatePicker

const AuditLog = () => {
	const {user, isAuthenticated, isLoading, getIdTokenClaims} = useAuth0()
	const navigate = useNavigate();
	const [fetchingData, setFetchingData] = useState(false);
	const [showFilter, setShowFilter] = useState(true);
	const [draw, setDraw] = useState(10)
	const [openedIndex, setOpenedIndex] = useState(0)
	const [page, setPage] = useState(1)
	const [rowPerPage, setRowPerPage] = useState(10)
	const [moduleXero, setModuleXero] = useState([])
	const [moduleXeroBackupDate, setModuleXeroBackupDate] = useState('')
	const [moduleXeroKeyword, setModuleXeroKeyword] = useState('')
	const [dateRange, setDateRange] = useState([])
	const [query, setQuery] = useState('')
	const [dropdownOpen, setDropdownOpen] = useState(false)
	const [selectedOrg, setSelectedOrg] = useState(null);
	const [organizations, setOrganizations] = useState([])
	const [logObject, setLogObject] = useState('')
	const [openLogPopup, setOpenLogUp] = useState(false)

	const [tableData, setTableData] = useState([])
	const [tableTotalCount, setTableTotalCount] = useState(0)
	const [token, setToken] = useState('')

	if(!isAuthenticated) {
		navigate("/login")
	}

	useEffect(() => {
		if (window.innerWidth < 600) {
			setShowFilter(false);
		}
	}, []);

	useEffect(() => {
		if(!isLoading && isAuthenticated) {
			(async () => {
				try {
					setFetchingData(true)

					const token = await getIdTokenClaims()

					setToken(token.__raw)

					fetchLogs(token.__raw)
				} catch(e) {
					console.error(e)
				}
			})()
		}
	}, [
		getIdTokenClaims, isLoading, isAuthenticated, page, rowPerPage,
		moduleXero, moduleXeroBackupDate, moduleXeroKeyword, dateRange,
		query, selectedOrg
	])

	useEffect(() => {
		if(token) {
			fetchOrgsList(token)
		}
	}, [token])

	const fetchLogs = (token) => {
		getLogs(token, {
			draw,
			page,
			rowPerPage,
			moduleXero,
			moduleXeroBackupDate,
			moduleXeroKeyword,
			dateRange,
			query,
			orgId: selectedOrg ? selectedOrg.id : null,
		}).then(res => {
			setTableData(res.data.aaData)
			setTableTotalCount(res.data.iTotalDisplayRecords)
		}).catch(err => {
			console.error(err)
		}).finally(() => {
			setFetchingData(false)
		})
	}

	const handlePageChange = (page, size) => {
		setPage(page)
		setRowPerPage(size)
	}

	const filterByModules = checkedModules => {
		console.log("filter", checkedModules)
		setModuleXero(checkedModules)
	}

	const handleDateChange = dates => {
		setDateRange(dates)
	}

	const handleSearchChange = useCallback(
		debounce(text => {
			setQuery(text)
		}, 500), []
	)

	const fetchOrgsList = (token, searchBy = "") => {
		getOrganizations(token, searchBy).then(res => {
			setOrganizations(res.data.accounts)
		}).catch(err => {
			alert("Couldn't fetch organizations. Please contact support.")
			console.error(err)
		})
	}

	const showLogPopup = (log) => {
		setLogObject(log)
		setOpenLogUp(true)
	}

    const columns = [
        {
            title: 'Id',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Username',
            dataIndex: 'username',
            key: 'username',
        },
        {
            title: 'Org. Name',
            dataIndex: 'org_name',
            key: 'orgName',
        },
        {
            title: 'Object',
            dataIndex: 'xero_object',
            key: 'object',
            render: (_, { xero_object }) => {
                return (
                    <div className={'truncate w-20 cursor-pointer'} onClick={() => showLogPopup(xero_object)}>
                        {xero_object}
                    </div>
                )
            },
        },
        {
            title: 'Backup Date',
            dataIndex: 'log_backup_date',
            key: 'backupDate',
        },
        {
            title: 'Creation Date',
            dataIndex: 'created_date',
            key: 'creationDate',
        },
        {
            title: 'Module',
            dataIndex: 'xero_module',
            key: 'module',
        },
        {
            title: 'Log',
            dataIndex: 'xero_log',
            key: 'log',
            render: (_, { xero_log }) => {
                return (
                    <div className={'text-gray-500 flex gap-2 items-center'}>
                        <div className={`h-2 w-2 rounded-full bg-red-300`} />
                        {xero_log}
                    </div>
                )
            },
        },
    ];

	const filterModules = [
		{ label: 'Currency', value: 'CURRENT' },
		{ label: 'Tax Rates', value: 'TAXRATES' },
		{ label: 'Account', value: 'ACCOUNT' },
		{ label: 'Items', value: 'ITEMS' },
		{ label: 'Contacts', value: 'CONTACTS' },
		{ label: 'Invoices', value: 'INVOICES' },
		{ label: 'Credit Notes', value: 'CREDITNOTES' },
		{ label: 'Payments', value: 'PAYMENTS' },
	];

	const handleOpenChange = (nextOpen, info) => {
		setDropdownOpen(nextOpen)
	}

	const handleOrgDropdownItemClick = (org) => {
		setSelectedOrg(org);
		setDropdownOpen(false);
	}

	const handleDropdownFilter = (e) => {
		const val = e.target.value;

		(async () => {
			try {
				const token = await getIdTokenClaims()
				fetchOrgsList(token.__raw, val)
			} catch (e) {
				console.error(e)
			}
		})()
	}

	const [renderDropdownList, setRenderDropdownList] = useState([])

	useEffect(() => {
		if (organizations.length) {
			setRenderDropdownList(organizations.map((item, index) => {
				return (
					<div key={index} className={'py-3 px-4 hover:bg-gray-50 cursor-pointer'}
						 onClick={() => {handleOrgDropdownItemClick(item)}}>
						{item.account}
					</div>
				)
			}))
		} else {
			setRenderDropdownList([
				(
					<div key={0} className={'py-3 px-4 hover:bg-gray-50 cursor-pointer'}>
						No organization found
					</div>
				)
			])
		}
	}, [organizations])

	const mobileRender = tableData.map((item, index) => {
		return (
			<div key={index} className={' border rounded-lg border-gray-300 text-sm py-3 mt-3 px-4 text-gray-500'} onClick={() => {setOpenedIndex(index)}}>
				<div className={'flex items-center justify-between cursor-pointer'}>
					<div className={'flex items-center gap-2'}>
						<ReactSVG src="/assets/icons/common-dropdown.svg"
								  className={`text-gray-700 w-6 h-6 flex items-center justify-center bg-gray-100 rounded-full ${openedIndex === index && 'rotate-180'}`} />
						<div className={' text-gray-500 font-medium'}>{item.username}</div>
					</div>
					<div className={'text-gray-500 flex gap-2 items-center'}>
						<div className={`h-2 w-2 rounded-full bg-red-300`} />
						{item.xero_log}
					</div>
				</div>
				{openedIndex === index && <div className={'mt-2'}>
					<div className="flex justify-between">
						<div>Org.Name:</div>
						<div>{item.org_name}</div>
					</div>
					<div className="flex justify-between">
						<div>Object:</div>
						<div>{item.xero_object.substring(0, 20)}...</div>
					</div>
					<div className="flex justify-between">
						<div>Backup Date:</div>
						<div>{item.log_backup_date}</div>
					</div>
					<div className="flex justify-between">
						<div>Creation Date:</div>
						<div>{item.created_date}</div>
					</div>
					<div className="flex justify-between">
						<div>Module:</div>
						<div>{item.xero_module}</div>
					</div>
				</div>}
			</div>
		)
	});


    return <div className={'px-8'}>
        <div className={'flex lg:items-center justify-between'}>
			<div>
				<Button className={'lg:hidden mb-2 flex items-center'}  onClick={() => {setShowFilter(!showFilter)}}>
					<ReactSVG src="/assets/icons/filter.svg" />
					Filter | 3
				</Button>
				{showFilter ? <div className={'lg:flex lg:gap-0 block items-center'}>
					<CheckboxDropdown filterByModules={filterByModules} filterModules={filterModules} />
					<Dropdown
						placement="bottomCenter"
						trigger={['click']}
						onOpenChange={handleOpenChange}
						open={dropdownOpen}
						dropdownRender={(menu) => (
							<div className={'bg-white w-full border border-gray-100 rounded-lg sadow-lg'}>
								<div className={'border-b border-gray-300 p-2'}>
									<div className={'flex items-center gap-2 rounded-lg hover:bggray-50 p-2'}>
										<ReactSVG src={'/assets/icons/search.svg'} className={'text-gray-400'}/>
										<input type="text" className={'outline-0 bg-transparent w-full'}
											   onKeyUp={(e) => {handleDropdownFilter(e)}}
											   placeholder={'Search by title or description...'}/>
									</div>
								</div>
								<div className={'mt-2 h-[300px] overflow-y-auto'}>
									{renderDropdownList}
								</div>
							</div>
						)}
					>
						<div
							className={'sm:w-[30%] w-full lg:ml-2 lg:mt-0 mt-2 cursor-pointer p-1.5 px-1.5 flex justify-between items-center rounded-lg border-gray-300 border text-medium'}>
							<div className={'font-medium text-sm'}>
								{selectedOrg ? selectedOrg.account : 'Select Organization'}
							</div>
							<ReactSVG src="/assets/icons/common-dropdown.svg" className={'text-gray-700'}/>
						</div>
					</Dropdown>
					<div className={'lg:ml-2 lg:mt-0 mt-2'}>
						<RangePicker onChange={(_, datesStr) => handleDateChange(datesStr)}/>
					</div>
				</div> : '' }
			</div>
			<div className={'lg:w-auto w-[6rem]'}>
				<Search handleChange={text => handleSearchChange(text)} placeholder={window.innerWidth > 600 ? 'Search by title or description...' : 'Search...'} />
			</div>
        </div>
        <div className={'mt-6 pb-20 lg:block hidden'}>
            <Table dataSource={tableData} pagination={false} columns={columns}
				loading={isLoading || fetchingData}
			/>
            <Pagination
                rootClassName={'mt-4'}
                simple={{ readOnly: true }}
                total={tableTotalCount}
                showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                defaultCurrent={page}
				onChange={(page, size) => {handlePageChange(page, size)}}
            />
        </div>
		<div className={'lg:hidden block'}>
			{mobileRender}
		</div>

		<SuccessModal title="Log object" text={logObject} modalOpen={openLogPopup} onModalClose={() => setOpenLogUp(false)} />
    </div>
}

export default AuditLog;
